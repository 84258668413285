import React, { useEffect, useMemo, useRef, useState } from 'react'
import MatchDayBlock from '../MatchDay';
import {useCustomTranslation} from '../../hooks/useCustomTranslation';
import {useRouter} from 'next/router';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectRoutePrev, setFull} from '../../features/app';
import {useAppDispatch, useScreen} from '../../hooks';
import Sidebar from '../Sidebar';
import MatchInfo from '../MatchInfo';
import { getVisibleSectionIndex, isMatchPageUrl } from '../../utils'
import SidebarMenu from '../SidebarMenu';
import MatchEvents from '../MatchEvents/MatchEvents';
import OddsBlock from '../OddsBlock';
import SurebetBlock from '../SurebetBlock';
import SeriesBlock from '../SeriesBlock';
import LineupsBlock from '../LineupsBlock';
import RatingBookmakersWithFavorite from '../RatingBookmakers';
import {
    bookmakersRatingFetchedSelector,
    getBookmakersRating,
} from '../../features/bookmakers';
import {getSurebets, topSurebetsFetchedSelector} from '../../features/surebets';
import RatingVirtualBookmakerBlock from '../RatingVirtual';
import {
    getRating,
    virtualBookmakerRatingFetchedSelector,
} from '../../features/virtual-bookmaker';
import ForecastBlock from '../ForecastBlock';
import {MatchStandings} from '../match';
import MatchDetails from '../MatchDetails';
import LastMatches from '../match/LastMatches';
import Head from 'next/head';
import {
    useGetMatchDetailsQuery,
    useGetMatchEventsQuery, useGetMatchForecastsQuery, useGetMatchLineupsQuery,
    useGetMatchQuery, useGetMatchStandingsQuery,
    useGetOddsQuery, useGetSeriesQuery,
    useGetSurebetsQuery,
} from '../../api';
import TwoMatchSerie from '../TwoMatchSerie'
import { APP_ROUTES } from '../../const'

type Props = {
    matchId: number | null,
    isBlockFullSize?: boolean,
    isMatchPage?: boolean,
}

export const MatchDetailBlocks = ({
    matchId,
    isBlockFullSize,
    isMatchPage,
}: Props) => {
    const { t } = useCustomTranslation('common')
    const router = useRouter()
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()

    const {isDesktop} = useScreen()

    /** Fetching data */

    const [matchPollingInterval, setMatchPollingInterval] = useState(0)

    // Same query for match and match-daily
    const matchQuery = useGetMatchQuery(
        { id: matchId, locale: i18n.language },
        {skip: false, pollingInterval: matchPollingInterval}
    )

    // If no matchId -> match-daily
    // else -> match selected / match page
    const _matchId: number | undefined = matchId || matchQuery.data?.data?.match_id
    const isMatchSelected = !!matchId || (!!_matchId && isBlockFullSize)
    const isMatchDay = !isMatchSelected
    const matchType = isMatchSelected ? 'match' : 'matchday'
    const queryOptions = { id: _matchId, locale: i18n.language }

    const queryUpdateInSeconds = matchQuery.data?.update || 0
    useEffect(() => {
        setMatchPollingInterval(queryUpdateInSeconds * 1000)
    }, [queryUpdateInSeconds]);

    const matchEventsQuery = useGetMatchEventsQuery(queryOptions, { skip: isMatchDay, pollingInterval: matchPollingInterval })

    // Odds data

    const [typeOdds, setTypeOdds] = useState('regular')
    const [oddTypeOdds, setOddTypeOdds] = useState('')
    const [handicap, setHandicap] = useState('')
    const [period, setPeriod] = useState(0)

    const oddsQuery = useGetOddsQuery({
        ...queryOptions,
        type: typeOdds,
        odd_type: oddTypeOdds,
        handicap,
        period,
    }, {
        skip: isMatchDay,
        pollingInterval: matchPollingInterval,
    }
    )

    // Surebets

    const surebetsQuery = useGetSurebetsQuery(queryOptions, { skip: isMatchDay })
    const surebets = surebetsQuery.data?.data?.surebets || []

    // Series

    const seriesQuery = useGetSeriesQuery(queryOptions, { skip: isMatchDay })

    // Lineups

    const lineupsQuery = useGetMatchLineupsQuery(queryOptions, { skip: isMatchDay,  pollingInterval: matchPollingInterval })

    // Forecasts

    const forecastsQuery = useGetMatchForecastsQuery(queryOptions, { skip: false })

    // Standings

    const standingsQuery = useGetMatchStandingsQuery(queryOptions, { skip: isMatchDay, pollingInterval: matchPollingInterval })

    // Match details

    const matchDetailsQuery = useGetMatchDetailsQuery(queryOptions, { skip: isMatchDay })

    // Global component loading

    const areQueriesFetching =
        matchQuery.isLoading ||
        matchEventsQuery.isLoading ||
        oddsQuery.isLoading ||
        surebetsQuery.isLoading ||
        lineupsQuery.isLoading ||
        forecastsQuery.isLoading ||
        standingsQuery.isLoading ||
        matchDetailsQuery.isLoading

    /** Reset state on each match select */

    useEffect(() => {
        if (!matchId) return

        // Reset menu on new match select
        setActiveIndexMenu('main')

        setOddTypeOdds('')
        setHandicap('')
        setPeriod(0)
    }, [matchId]);

    // Reset full-size mode
    useEffect(() => {
        return () => {
            dispatch(setFull(false))
        }
    }, [])

    /** MatchDay data */

    //@todo Replace on queries + matchday miss data for SurebetBlock
    const bookmakersRating = useSelector(bookmakersRatingFetchedSelector)
    const topSurebets = useSelector(topSurebetsFetchedSelector)
    const ratingVirtual = useSelector(virtualBookmakerRatingFetchedSelector)

    const isMatchDayFetching =
        bookmakersRating.status.rating === 'loading' ||
        topSurebets.status.top === 'loading' ||
        ratingVirtual.status.rating === 'loading'

    useEffect(() => {
        if (!isMatchDay) return

        dispatch(getBookmakersRating())
        dispatch(getSurebets())
        dispatch(getRating())
    }, [isMatchDay]);

    /** Handle block resize */

    const routePrev = useSelector(selectRoutePrev)

    function onBlockResize(isFull: boolean) {
        dispatch(setFull(isFull))
        setActiveIndexMenu('main')

        // Return to prev or main page
        if (!isFull) {
            router.push(
                routePrev || APP_ROUTES.MAIN,
                undefined,
                {shallow: !isMatchPage, scroll: true}
            )
            return
        }

        if (!matchQuery.data?.data) return

        // Replace url with match page
        let url = matchQuery.data.data.url
        if (url.charAt(url.length - 1) === '/') {
            url = url.slice(0, -1);
        }

        router.push(
            { pathname: router.locale + url },
            undefined,
            { shallow: true, scroll: true }
        )
    }

    /** Auto-toggle match sidebar on page change. Examples:
     * - click link to league page in sidebar
     * - go back/forward in browser history */
    useEffect(() => {
        const isMatchPage = isMatchPageUrl(`/${router.locale}${router.asPath}`)
        if (isMatchPage && !isBlockFullSize) dispatch(setFull(true))
        else if (!isMatchPage && isBlockFullSize) dispatch(setFull(false))
    }, [router.asPath])

    /** Sticky menu on scroll */

    const [activeIndexMenu, setActiveIndexMenu] = useState('main')
    const [isMenuSticky, setIsMenuSticky] = useState(false)
    const [showMatchInfo, setShowMatchInfo] = useState(false)

    const sectionsRef = useRef<Record<string, any>>({})
    const sidebarMenuRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (sectionsRef.current && !!sectionsRef.current['main']) {
                const block = sectionsRef.current['main']
                const blockTop = block.getBoundingClientRect().top
                const blockBottom = block.getBoundingClientRect().bottom
                const OFFSET_HEIGHT = 40 + 40

                setShowMatchInfo(!(blockTop < window.innerHeight && blockBottom - OFFSET_HEIGHT >= 0))
            }

            if (!isMatchSelected) return

            const visibleSectionIndex = getVisibleSectionIndex(sectionsRef.current)
            if (visibleSectionIndex) setActiveIndexMenu(visibleSectionIndex)
            if (sidebarMenuRef.current) {
                const rect = sidebarMenuRef.current.getBoundingClientRect()
                setIsMenuSticky(rect.top <= 61 && rect.bottom >= 61)
            }
        }

        handleScroll()

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isMatchSelected])

    function onChangeActiveIndex(id: string) {
        if (sectionsRef.current && sectionsRef.current[id]) {
            const headerOffset = 120
            const elementPosition = sectionsRef.current[id].getBoundingClientRect().top
            const offsetPosition = elementPosition + window.scrollY - headerOffset
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })
        }
    }

    /** Menu for switching match sidebar sections */

    const showOdds = !!oddsQuery.data?.data
    const showSurebet = surebets.length > 0
    const showSeries = seriesQuery.data?.data?.series.length > 0

    const lineupsData = lineupsQuery?.data?.data
    const showLineups =  lineupsData?.lineups &&
        lineupsData?.lineups[0]?.lineup &&
        lineupsData?.lineups[1]?.lineup

    const showForecast =
        (isMatchDay && forecastsQuery.data?.data?.predictions.length > 0) ||
        (isMatchSelected && forecastsQuery.data?.data?.predictions.length == 1)

    const standings = standingsQuery.data?.data
    const showStandings = useMemo(() => {
        if (!standings || standingsQuery.isLoading) return false

        const teams = (standings.standings.groups || [])[0]?.table
        const areStandingsAvailable = teams && teams.length > 0
        const areFixturesAvailable = (standings.fixtures?.matches || []).length > 0
        const isPlayoffAvailable = standings.playoff && standings.playoff.length > 0
        return areStandingsAvailable || areFixturesAvailable || isPlayoffAvailable
    }, [standingsQuery.isLoading]);

    const menuList = [
        {
            id: 'main',
            label: t('match_menu.main'),
        },
        showOdds && {
            id: 'odds',
            label: t('match_menu.odds'),
        },
        showSurebet && {
            id: 'surebet',
            label: t('match_menu.surebets'),
        },
        showForecast && {
            id: 'forecast',
            label: t('match_menu.tips'),
        },
        showSeries && {
            id: 'series',
            label: t('match_menu.series'),
        },
        showLineups && {
            id: 'lineups',
            label: t('match_menu.lineups'),
        },
        showStandings && {
            id: 'standings',
            label: t('match_menu.tables'),
        },
        isMatchSelected && {
            id: 'matches_last',
            label: t('match_menu.matches'),
        },
    ].filter(Boolean)

    return (
        <Sidebar
            side='right'
            loading={areQueriesFetching || isMatchDayFetching}
            full={isBlockFullSize}
        >
            <MatchDayBlock
                matchday={matchQuery.data?.data}
                ref={(el) => (sectionsRef.current['main'] = el)}
                type={matchType}
                full={isBlockFullSize}
                onChange={onBlockResize}
            />
            {isMatchDay && (
                <>
                    <RatingBookmakersWithFavorite
                        items={bookmakersRating.rating}
                    />
                    {topSurebets.top.length > 0 && (
                        <SurebetBlock
                            surebets={topSurebets.top}
                            typeMatch={matchType}
                        />
                    )}
                    <RatingVirtualBookmakerBlock
                        items={ratingVirtual.rating}
                    />
                    {showForecast && (
                        <ForecastBlock
                            forecasts={forecastsQuery.data.data.predictions}
                            ref={(el) => (sectionsRef.current['forecast'] = el)}
                            typeMatch={matchType}
                            full={isBlockFullSize}
                        />
                    )}
                    {/*Hide news for now*/}
                    {/*<NewsBlock />*/}
                </>
            )}
            {isMatchSelected && matchQuery.data?.data && (
                <>
                    {isBlockFullSize && matchQuery.data.meta && (
                        <Head>
                            <title>
                                {matchQuery.data.meta.title}
                            </title>
                            <meta
                                name="description"
                                content={matchQuery.data.meta.description}
                            />
                        </Head>
                    )}
                    <MatchInfo
                        match={matchQuery.data?.data}
                        full={isBlockFullSize}
                        show={showMatchInfo}
                        onToggleSidebar={onBlockResize}
                    />
                    {matchQuery.data?.data?.related && (
                        <TwoMatchSerie
                            related={matchQuery.data.data.related}
                        />
                    )}
                    <SidebarMenu
                        menuList={menuList}
                        ref={sidebarMenuRef}
                        full={isBlockFullSize}
                        sticky={isMenuSticky}
                        indexActive={activeIndexMenu}
                        onChange={onChangeActiveIndex}
                    />
                    {(matchEventsQuery.data?.data?.incidents || []).length > 0 && (
                        <MatchEvents
                            items={matchEventsQuery.data?.data?.incidents}
                            full={isBlockFullSize}
                        />
                    )}
                    {/*@todo Refactor odds type, now it's used in 2 diff states -> wrong!*/}
                    {showOdds && (
                        <OddsBlock
                            odds={oddsQuery.data.data}
                            oddsTypeOutside={typeOdds}
                            ref={(el) => (sectionsRef.current['odds'] = el)}

                            matchId={matchQuery.data.data.match_id}
                            isMatchLive={matchQuery.data.data.live}
                            matchDateStart={matchQuery.data.data.date_start}
                            teamOneTitle={matchQuery.data.data.team1.title}
                            teamTwoTitle={matchQuery.data.data.team1.title}

                            isBlockFullSize={isBlockFullSize}
                            onChangeHandicap={setHandicap}
                            onChangeOddType={setOddTypeOdds}
                            onChangePeriod={setPeriod}
                            onChangeType={setTypeOdds}
                            loading={oddsQuery.isFetching}
                        />
                    )}
                    {showSurebet && (
                        <SurebetBlock
                            ref={(el) => (sectionsRef.current['surebet'] = el)}
                            full={isBlockFullSize}
                            surebets={surebets}
                        />
                    )}
                    {showForecast && (
                        <ForecastBlock
                            forecasts={forecastsQuery.data.data.predictions}
                            ref={(el) => (sectionsRef.current['forecast'] = el)}
                            typeMatch={matchType}
                            full={isBlockFullSize}
                        />
                    )}
                    {/*@todo Refactor entry types*/}
                    {showSeries && (
                        <SeriesBlock
                            ref={(el) => (sectionsRef.current['series'] = el)}
                            full={isBlockFullSize}
                            series={seriesQuery}
                            match_id={String(matchId)}
                        />
                    )}
                    {showLineups && (
                        <LineupsBlock
                            ref={(el) => (sectionsRef.current['lineups'] = el)}
                            sidebarView={!isBlockFullSize || !isDesktop}
                            full={isBlockFullSize}
                            lineups={lineupsQuery.data?.data?.lineups}
                        />
                    )}
                    {showStandings && (
                        <MatchStandings
                            ref={(el) => (sectionsRef.current['standings'] = el)}
                            data={standingsQuery.data?.data}
                        />
                    )}
                    {matchQuery.data?.data && (
                        <LastMatches
                            ref={(el) => (sectionsRef.current['matches_last'] = el)}
                            team1={matchQuery.data.data.team1}
                            team2={matchQuery.data.data.team2}
                        />
                    )}
                    {matchDetailsQuery.data?.data?.incidents.length > 0 && (
                        <MatchDetails items={matchDetailsQuery.data.data.incidents} />
                    )}
                </>
            )}
        </Sidebar>
    )
}